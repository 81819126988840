import React from "react";
import * as Icon from "react-feather";
const navigationConfig = [
  {
    id: "dashboard",
    title: "Dashboard",
    type: "item",
    icon: <Icon.Home size={20} />,
    //permissions: ["super-admin", "executive", "data-entry-operator"],
    permissions: [
      "super-admin",
      "executive",
      "executive-Mathematics",
      "executive-English",
      "executive-Science",
      "executive-Reasoning",
      "executive-Physics",
      "executive-Chemistry",
      "executive-Biology",
      "data-entry-operator",
      "online-class-operator",
      "exam-operator",
      "subscription-operator",
      "sales",
      "hr",
      "co-ordinator",
    ],
    navLink: "/",
  },

  // {
  //   id: "adminrole",
  //   title: "Admin Roles",
  //   icon: <Icon.Users size={20} />,
  //   permissions: ["super-admin"],
  //   type: "item",
  //   navLink: "/admin-roles",
  // },
  {
    id: "student",
    title: "Student",
    icon: <Icon.User size={20} />,
    permissions: ["super-admin", "sales"],
    // permissions: [],
    type: "item",
    navLink: "/student",
  },

  {
    id: "curriculum",
    title: "Curriculum",
    icon: <Icon.Book size={20} />,
    permissions: ["super-admin"],
    // permissions: [],
    type: "item",
    navLink: "/curriculum",
  },

  {
    id: "createnewAccount",
    title: "Create new Account",
    icon: <Icon.User size={20} />,
    permissions: ["sales"],
    // permissions: [],
    type: "item",
    navLink: "/newregister",
  },

  {
    id: "teacher",
    title: "Teacher",
    icon: <Icon.User size={20} />,
    permissions: ["super-admin", "hr"],
    // permissions: [""],
    type: "item",
    navLink: "/teacher",
  },

  {
    id: "Zoom Host",
    title: "Zoom Hosts",
    icon: <Icon.User size={20} />,
    permissions: ["super-admin", "online-class-operator"],
    // permissions: [],
    type: "item",
    navLink: "/zoom-users",
  },

  {
    id: "classes",
    title: "Classes",
    icon: <Icon.Tv size={20} />,
    //permissions: ["super-admin"],
    permissions: ["super-admin", "online-class-operator", "sales"],
    type: "collapse",

    // navLink: "/classes",
    children: [
      {
        id: "classes-class",
        title: "Classes",
        permissions: ["super-admin", "online-class-operator", "sales"],
        type: "item",
        navLink: "/classes",
      },
      {
        id: "teachers-class",
        title: "Teachers",
        permissions: ["super-admin", "online-class-operator"],
        type: "item",
        navLink: "/teachers-classes",
      },
    ],
  },
  {
    id: "co-curricular",
    title: "co-curricular",
    icon: <Icon.Film size={20} />,
    permissions: ["super-admin", "online-class-operator", "sales"],
    type: "collapse",
    // navLink: "/co-curricular",
    children: [
      {
        id: "co-curricular-class",
        title: "co-curricular",
        permissions: ["super-admin", "online-class-operator", "sales"],
        type: "item",
        navLink: "/co-curricular",
      },
      {
        id: "teachers-class",
        title: "Teachers",
        permissions: ["super-admin", "online-class-operator"],
        type: "item",
        navLink: "/teachers-cocurricular-classes",
      },
    ],
  },
  {
    id: "demo-users",
    title: "Demo Users",
    icon: <Icon.Users size={20} />,
    permissions: ["super-admin", "sales"],
    type: "item",
    navLink: "/demo-users",
  },
  {
    id: "questionlist",
    title: "Questions",
    icon: <Icon.Book size={20} />,
    permissions: ["super-admin"],
    type: "collapse",
    // navLink: "/questions-listing",
    children: [
      {
        id: "approvedQuestions",
        title: "Approved Questions",
        permissions: [
          "super-admin",
          "executive",
          "executive-Mathematics",
          "executive-English",
          "executive-Science",
          "executive-Reasoning",
          "executive-Physics",
          "executive-Chemistry",
          "executive-Biology",
        ],
        type: "item",
        navLink: "/approved-questions",
      },
    ],
  },
  {
    id: "olympiadquestionlist",
    title: "Olympiad Questions",
    icon: <Icon.Book size={20} />,
    permissions: ["super-admin"],
    type: "collapse",
    // navLink: "/questions-listing",
    children: [
      {
        id: "olympiadapprovedQuestions",
        title: "Approved Questions",
        permissions: [
          "super-admin",
          "executive",
          "executive-Mathematics",
          "executive-English",
          "executive-Science",
          "executive-Reasoning",
          "executive-Physics",
          "executive-Chemistry",
          "executive-Biology",
        ],
        type: "item",
        navLink: "/olympiad-approved-questions",
      },
    ],
  },
  {
    id: "questionlistOP",
    title: "Executive Questions",
    icon: <Icon.Book size={20} />,
    // permissions: ["super-admin", "executive"],
    permissions: [
      "executive",
      "executive-Mathematics",
      "executive-English",
      "executive-Science",
      "executive-Physics",
      "executive-Chemistry",
      "executive-Biology",
    ],
    type: "collapse",
    children: [
      {
        id: "approvedQuestions",
        title: "Approved Questions",
        permissions: [
          "super-admin",
          "executive",
          "executive-Mathematics",
          "executive-English",
          "executive-Science",
          "executive-Physics",
          "executive-Chemistry",
          "executive-Biology",
        ],
        type: "item",
        navLink: "/approved-questions",
      },
      {
        id: "uploadedQuestions",
        title: "Uploaded Questions",
        permissions: [
          "super-admin",
          "executive",
          "executive-Mathematics",
          "executive-English",
          "executive-Science",
          "executive-Physics",
          "executive-Chemistry",
          "executive-Biology",
        ],
        type: "item",
        navLink: "/uploaded-questions",
      },
    ],
  },

  {
    id: "olympiadquestionlistOP",
    title: "Olympiad Executive Questions",
    icon: <Icon.Book size={20} />,
    // permissions: ["super-admin", "executive"],
    permissions: [
      "executive",
      "executive-Mathematics",
      "executive-English",
      "executive-Science",
      "executive-Reasoning",
      "executive-Physics",
      "executive-Chemistry",
      "executive-Biology",
    ],
    type: "collapse",
    children: [
      {
        id: "olympiadapprovedQuestions",
        title: "Approved Questions",
        permissions: [
          "super-admin",
          "executive",
          "executive-Mathematics",
          "executive-English",
          "executive-Science",
          "executive-Reasoning",
          "executive-Physics",
          "executive-Chemistry",
          "executive-Biology",
        ],
        type: "item",
        navLink: "/olympiad-approved-questions",
      },
      {
        id: "olympiaduploadedQuestions",
        title: "Uploaded Questions",
        permissions: [
          "super-admin",
          "executive",
          "executive-Mathematics",
          "executive-English",
          "executive-Science",
          "executive-Reasoning",
          "executive-Physics",
          "executive-Chemistry",
          "executive-Biology",
        ],
        type: "item",
        navLink: "/olympiad-uploaded-questions",
      },
    ],
  },

  // {
  //   id: "manage",
  //   title: "Manage",
  //   icon: <Icon.Users size={20} />,
  //   permissions: ["super-admin"],
  //   type: "collapse",
  //   children: [
  //     {
  //       id: "class",
  //       title: "Class",
  //       // icon: <Icon.Users size={20} />,
  //       permissions: ["super-admin", "data-entry-operator"],
  //       type: "item",
  //       navLink: "/manage-class",
  //     },
  //     {
  //       id: "board",
  //       title: "Board",
  //       // icon: <Icon.Users size={20} />,
  //       permissions: ["super-admin", "data-entry-operator"],
  //       type: "item",
  //       navLink: "/manage-board",
  //     },
  //     {
  //       id: "subject",
  //       title: "Subject",
  //       // icon: <Icon.Users size={20} />,
  //       permissions: ["super-admin", "data-entry-operator"],
  //       type: "item",
  //       navLink: "/manage-subject",
  //     },
  //   ],
  // },
  {
    id: "subject",
    title: "Subjects",
    icon: <Icon.Users size={20} />,
    permissions: ["super-admin"],
    type: "item",
    navLink: "/manage-subject",
  },

  {
    id: "Co-Curricular Activity",
    title: "Co-Curricular Activity",
    icon: <Icon.Users size={20} />,
    permissions: ["super-admin"],
    type: "item",
    navLink: "/manage-cocurricularsubject",
},


  {
    id: "boards",
    title: "Boards",
    type: "collapse",
    icon: <Icon.Home size={20} />,
    //permissions: ["super-admin", "executive", "data-entry-operator"],
    permissions: ["super-admin"],
  },
  {
    id: "0lympiadBoards",
    title: "Olympiad-Boards",
    type: "collapse",
    icon: <Icon.Home size={20} />,
    //permissions: ["super-admin", "executive", "data-entry-operator"],
    permissions: ["super-admin"],
  },
  {
    id: "operator",
    title: "Operator Questions",
    icon: <Icon.BookOpen size={20} />,
    // permissions: ["super-admin", "data-entry-operator"],
    permissions: ["data-entry-operator"],
    type: "item",
    navLink: "/operator-questions",
  },
  {
    id: "olympiadQue",
    title: "Olympiad Questions",
    icon: <Icon.BookOpen size={20} />,
    // permissions: ["super-admin", "data-entry-operator"],
    permissions: ["data-entry-operator"],
    type: "item",
    navLink: "/olympiad-questions",
  },
  {
    id: "examtestslist",
    title: "Exams & Tests",
    icon: <Icon.BookOpen size={20} />,
    permissions: ["super-admin", "exam-operator"],
    // permissions: [""],
    type: "collapse",

    children: [
      {
        id: "Regular Exams",
        title: "Regular Exams",
        permissions: ["super-admin", "exam-operator"],
        type: "item",
        navLink: "/regular-exams",
      },
      {
        id: "Olympiad Exams",
        title: "Olympiad Exams",
        permissions: ["super-admin", "exam-operator"],
        type: "item",
        navLink: "/olympiad-exams",
      },
    ],
  },

  {
    id: "payments",
    title: "Payments",
    icon: <Icon.CreditCard size={20} />,
    permissions: ["super-admin", "subscription-operator"],
    // permissions: [""],
    type: "collapse",
    children: [
      {
        id: "Course",
        title: "Course",
        permissions: ["super-admin", "subscription-operator"],
        type: "item",
        navLink: "/course-payments",
      },
      {
        id: "Exam",
        title: "Exam",
        permissions: ["super-admin", "subscription-operator"],
        type: "item",
        navLink: "/exam-payments",
      },
      {
        id: "Cocurricular",
        title: "Cocurricular",
        permissions: ["super-admin", "subscription-operator"],
        type: "item",
        navLink: "/cocurricular-course-payments",
      },
    ],
  },
  {
    id: "discounts",
    title: "discounts",
    icon: <Icon.Percent size={20} />,
    permissions: ["super-admin", "subscription-operator", "sales"],
    // permissions: [""],
    type: "item",
    navLink: "/discounts",
  },
  {
    id: "Image",
    title: "Image / WorkSheet",
    icon: <Icon.Image size={20} />,
    // permissions: ["super-admin", "data-entry-operator", "executive"],
    permissions: ["super-admin"],
    type: "item",
    navLink: "/manage-images",
  },
  {
    id: "Gamification",
    title: "Gamification",
    icon: <Icon.Play size={20} />,
    // permissions: ["super-admin", "data-entry-operator", "executive"],
    permissions: ["super-admin"],
    type: "item",
    navLink: "/gamification",
  },
  {
    id: "sub-admin",
    title: "Sub-admin",
    icon: <Icon.Users size={20} />,
    permissions: ["super-admin", "executive"],
    type: "collapse",
    children: [
      {
        id: "Users",
        title: "Users",
        // icon: <Icon.Users size={20} />,
        permissions: ["super-admin", "executive"],
        type: "item",
        navLink: "/admin-roles",
      },
      {
        id: "Admin roles",
        title: "Admin Roles",
        // icon: <Icon.Users size={20} />,
        permissions: ["super-admin"],
        type: "item",
        navLink: "/subuser",
      },
    ],
  },
  {
    id: "manage-attendance",
    title: "Manage-Attendance",
    icon: <Icon.User size={20} />,
    // permissions: ["super-admin", "data-entry-operator", "executive"],
    permissions: ["co-ordinator", "super-admin", "sales"],
    type: "item",
    navLink: "/manage-attendance",
  },
  {
    id: "reserve-class",
    title: "Reserve Class",
    icon: <Icon.User size={20} />,
    // permissions: ["super-admin", "data-entry-operator", "executive"],
    permissions: ["co-ordinator", "sales"],
    type: "item",
    navLink: "/reserve-class",
  },
];

export default navigationConfig;
