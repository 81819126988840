import http from "../../../views/tautmore-components/services/api-fetch/Axios";
const config = {
  headers: {
    "Content-Type": "application/json",
    accept: "application/json",
  },
};

export const updateRegistrationData = (data) => {
  return (dispatch) => {
    dispatch({
      type: "UPDATE_REGISTRATION_DATA",
      data,
    });
  };
};

export const saveData = (data) => {
  console.log(data,"---------DATAAAAAA-------");
  return (dispatch) => {
    dispatch({
      type: "SAVE_DATA",
      data,
    });
  };
};


export const clearAllDataAction = () => {
  return (dispatch) => {
    dispatch({
      type: "CLEAR_ALL_DATA",
    });
  };
};

// export const sendOtpParentAction = (data) => {
//     return (dispatch) => {
//      http.post("/api/parent/send-otp",
//           {
//             ...data,
//           }
//         )
//         .then((response) => {
//           dispatch({ type: "SEND_OTP_PARENT", response });
//         })
//         .catch((error) => {
//           dispatch({ type: "SEND_OTP_PARENT", error });
//         });
//     };
// };

export const sendOtpParentAction = (data) => {
  return (dispatch) => {
    http
      .post("/api/parent/admin/submit-parent-data", {
        ...data,
      })
      .then((response) => {
        dispatch({ type: "SEND_OTP_PARENT", response });
      })
      .catch((error) => {
        dispatch({ type: "SEND_OTP_PARENT", error });
      });
  };
};

export const verifyEmailAction = (data) => {
  return (dispatch) => {
    http
      .get(`/api/parent/check-email-is-verified/${data?.parentId}`, {
        ...data,
      })
      .then((response) => {
        dispatch({ type: "VERIFY_EMAIL", response });
      })
      .catch((error) => {
        dispatch({ type: "VERIFY_EMAIL", error });
      });
  };
};

export const verifyOtpParentAction = (data) => {
  return (dispatch) => {
    http
      .post("/api/parent/verify-otp", {
        ...data,
      })
      .then((response) => {
        dispatch({ type: "VERIFY_OTP_PARENT", response });
      })
      .catch((error) => {
        dispatch({ type: "VERIFY_OTP_PARENT", error });
      });
  };
};

export const coCurricularActivitiesAction = () => {
  return (dispatch) => {
    http
      .get("/api/students/co-curricular-activities")
      .then((response) => {
        dispatch({ type: "COCURRICULAR_ACTIVITIES", response });
      })
      .catch((error) => {
        dispatch({ type: "COCURRICULAR_ACTIVITIES", error });
      });
  };
};

export const getCountries = () => {
  return (dispatch) => {
    http
      .get("/api/admin/countries-list")
      .then((response) => {
        dispatch({ type: "COUNTRY_LIST", response });
      })
      .catch((error) => {
        dispatch({ type: "COUNTRY_LIST", error });
      });
  };
};

export const getAllStates = (data) => {
  return (dispatch) => {
    http
      .get(`/api/admin/state-list?country_code=${data}`)
      .then((response) => {
        dispatch({ type: "STATE_LIST", response });
      })
      .catch((error) => {
        dispatch({ type: "STATE_LIST", error });
      });
  };
};

export const getTimezoneList = (data) => {
  return (dispatch) => {
    http
      .get(`/api/admin/timezone-by-country-code?countryCode=${data}`)
      .then((response) => {
        dispatch({ type: "TIME_ZONE", response });
      })
      .catch((error) => {
        dispatch({ type: "TIME_ZONE", error });
      });
  };
};

export const getAllBoards = () => {
  return (dispatch) => {
    http
      .get(`/api/board/all-boards`)
      .then((response) => {
        dispatch({ type: "ALL_BOARDS", response });
      })
      .catch((error) => {
        dispatch({ type: "ALL_BOARDS", error });
      });
  };
};

export const getAllGradesAction = (data) => {
  return (dispatch) => {
    http
      .get(`/api/class/grades-by-board/${data?.boardId}`)
      .then((response) => {
        dispatch({ type: "ALL_GRADES", response });
      })
      .catch((error) => {
        dispatch({ type: "ALL_GRADES", error });
      });
  };
};

export const getSubjectsByclassAction = (data) => {
  return (dispatch) => {
    http
      .post(`/api/syllabus/subjects-by-class`, {
        ...data,
      })
      .then((response) => {
        dispatch({ type: "SUBJECT_BY_CLASS", response });
      })
      .catch((error) => {
        dispatch({ type: "SUBJECT_BY_CLASS", error });
      });
  };
};

export const getAllMonthSlabs = () => {
  return (dispatch) => {
    http
      .get(`/api/subscription/month-slabs`)
      .then((response) => {
        dispatch({ type: "MONTHS_SLABS", response });
      })
      .catch((error) => {
        dispatch({ type: "MONTHS_SLABS", error });
      });
  };
};

export const register = (data) => {
  let payload;
  console.log(data)
  if(data?.action=="renewal"){
    if(data?.renewal=="curriculum"){
       payload = {subjectsEnrolled:data.subjectsEnrolled}
    }
    if(data?.renewal=="olympiad"){
      payload = {olympiadSubscription:data.olympiadSubscription}
    }
    if(data?.renewal=="cocurricular"){
      payload = {cocurricularsEnrolled:data.cocurricularsEnrolled}
    }
    
    return (dispatch) => {
      http
        .post(`api/subscription/renew-student-subscription/${data.studentId}`, {
          ...payload,
        })
        .then((response) => {
          dispatch({ type: "UPDATE_STUDENT_DATA", response });
        })
        .catch((error) => {
          dispatch({ type: "UPDATE_STUDENT_DATA", error });
        });
    };
  }
  
  if(data?.action=="subscribemore"){
    return (dispatch) => {
      http
        .post(`api/subscription/add-student-subscription/${data.studentId}`, {
          ...data,
        })
        .then((response) => {
          dispatch({ type: "UPDATE_STUDENT_DATA", response });
        })
        .catch((error) => {
          dispatch({ type: "UPDATE_STUDENT_DATA", error });
        });
    };
  }else{
    return (dispatch) => {
      http
        .post(`/api/students/register`, {
          ...data,
        })
        .then((response) => {
          dispatch({ type: "UPDATE_STUDENT_DATA", response });
        })
        .catch((error) => {
          dispatch({ type: "UPDATE_STUDENT_DATA", error });
        });
    };
  }

 
};

export const getParentStudentSubscription = (params) => {
  return (dispatch) => {
    const headers = {
      registeredBy: params.action=="subscribemore" || params.action=="renewal" ? "parent" : "admin" ,
    };
    http
      .get(
        `/api/subscription/get-student-subscription/${params?.parentId}/null`,
        {
          headers,
        }
      )
      .then((response) => {
        dispatch({ type: "STUDENT_SUBSCRIPTION_DETAILS", response });
      })
      .catch((error) => {
        dispatch({ type: "STUDENT_SUBSCRIPTION_DETAILS", error });
      });
  };
};

export const removeStudenSubscription = (params) => {
  return (dispatch) => {
    http
      .delete(
        `/api/subscription/remove-student-subscription/${params.StudentID}`
      )
      .then((response) => {
        dispatch({ type: "REMOVE_STUDENT", response });
      })
      .catch((error) => {
        dispatch({ type: "REMOVE_STUDENT", error });
      });
  };
};

export const applyCuponDiscountAction = (params) => {
  return (dispatch) => {
    http
      .post(`/api/subscription/apply-coupon/${params.parentID}/null`, {
        ...params.reqBodyData,
      })
      .then((response) => {
        dispatch({ type: "APPLY_COUPON", response });
      })
      .catch((error) => {
        dispatch({ type: "APPLY_COUPON", error });
      });
  };
};


export const createPaymentLinkAction = (data) => {
  return (dispatch) => {
    http
      .post("/api/payments/razorpay/payment-link", {
        ...data,
      })
      .then((response) => {
        dispatch({ type: "SEND_PAYMENT_LINK", response });
      })
      .catch((error) => {
        dispatch({ type: "SEND_PAYMENT_LINK", error });
      });
  };
};


export const bankTransferOrderAction = (data) => {
  return (dispatch) => {
    http
      .post("/api/payments/bank-transfer/create-order", {
        ...data,
      })
      .then((response) => {
        dispatch({ type: "SEND_PAYMENT_LINK", response });
      })
      .catch((error) => {
        dispatch({ type: "SEND_PAYMENT_LINK", error });
      });
  };
};