

const initialState = {
  registrationData: {},
  otp: [],
  verifyEmail: {},
  verifyOtpParent: [],
  allMonthsSlabs: {},
  AllGrades: [],
  AllBoards: [],
  // Subjects: [],
  SubjectsByclass: [],
  countryList: null,
  statesList: [],
  timezoneList: [],
  registeredStudentData: {},
  parentStuData: {},
  removeStudentSubs: [],
  appyCuponDiscount: [],
  paymentLinkData: {},
  savedData: null
};

const regsiterReducer = (state = initialState, action) => {
  switch (action.type) {
    case "UPDATE_REGISTRATION_DATA":
      return {
        ...state,
        registrationData: action.data,
      };

    case "CLEAR_ALL_DATA":
      return {
        ...state,
        registrationData: {},
        otp: [],
        verifyEmail: {},
        verifyOtpParent: [],
        allMonthsSlabs: {},
        AllGrades: [],
        AllBoards: [],
        // Subjects: [],
        SubjectsByclass: [],
        countryList: null,
        statesList: [],
        timezoneList: [],
        registeredStudentData: {},
        parentStuData: {},
        removeStudentSubs: [],
        appyCuponDiscount: [],
        paymentLinkData: {},
      };

    case "SEND_OTP_PARENT":
      return {
        ...state,
        otp: action?.response?.data
          ? action?.response?.data
          : action?.error?.response?.data,
      };

    case "VERIFY_EMAIL":
      return {
        ...state,
        verifyEmail: action?.response?.data
          ? action.response.data
          : action?.error?.response?.data,
      };

    case "VERIFY_OTP_PARENT":
      return {
        ...state,
        verifyOtpParent: action?.response?.data
          ? action.response?.data
          : action?.error?.response?.data,
      };
    case "COCURRICULAR_ACTIVITIES":
      return {
        ...state,
        CoCurricularActivities: action?.response?.data
          ? action.response?.data
          : action?.error?.response?.data,
      };

    case "COUNTRY_LIST":
      return {
        ...state,
        countryList: action?.response?.data
          ? action.response?.data
          : action?.error?.response?.data,
      };

    case "STATE_LIST":
      return {
        ...state,
        statesList: action?.response?.data
          ? action.response?.data
          : action?.error?.response?.data,
      };

    case "TIME_ZONE":
      return {
        ...state,
        timezoneList: action?.response?.data
          ? action.response?.data
          : action?.error?.response?.data,
      };

    case "ALL_BOARDS":
      return {
        ...state,
        AllBoards: action?.response?.data
          ? action.response?.data
          : action?.error?.response?.data,
      };

    case "ALL_GRADES":
      return {
        ...state,
        AllGrades: action?.response?.data
          ? action.response?.data
          : action?.error?.response?.data,
      };

    case "SUBJECT_BY_CLASS":
      return {
        ...state,
        SubjectsByclass: action?.response?.data
          ? action.response?.data
          : action?.error?.response?.data,
      };

    case "MONTHS_SLABS":
      return {
        ...state,
        allMonthsSlabs: action?.response?.data
          ? action.response?.data
          : action?.error?.response?.data,
      };

    case "UPDATE_STUDENT_DATA":
      return {
        ...state,
        registeredStudentData: action?.response?.data
          ? action.response?.data
          : action?.error?.response?.data,
      };

    case "STUDENT_SUBSCRIPTION_DETAILS":
      return {
        ...state,
        parentStuData: action?.response?.data
          ? action.response?.data
          : action?.error?.response?.data,
      };

    case "REMOVE_STUDENT":
      return {
        ...state,
        removeStudentSubs: action?.response
          ? action?.response
          : action?.response?.message,
      };

    case "APPLY_COUPON":
      return {
        ...state,
        appyCuponDiscount: action?.response?.data
          ? action.response?.data
          : action?.error?.response?.data,
      };

    case "SEND_PAYMENT_LINK":
      return {
        ...state,
        paymentLinkData: action?.response?.data
          ? action.response?.data
          : action?.error?.response?.data,
      };

    case "SAVE_DATA":
      return {
        ...state,
        savedData: action?.data
      };

    default:
      return state;
  }
};

export default regsiterReducer;
